<script>
import {
  ArrowUpIcon,
  HeartIcon,
  EyeIcon,
  ShoppingCartIcon,
  KeyIcon,
  PhoneIcon,
  MailIcon,
  SettingsIcon,
  FileTextIcon,
  MapPinIcon,
    LayersIcon,
  UserIcon,UsersIcon,
  UserCheckIcon, UserPlusIcon,
} from "vue-feather-icons";
import { Carousel, Slide } from "vue-carousel";

import Navbar from "@/components/navbar0";
import Pricing from "@/components/pricing_min";
import Footer from "@/components/footer";
import store from "@/store/store";
import PricingNew from "@/components/pricing_recurrent_parent";
import MemberHighlight from "./MemberHighlight"

/**
 * Shop-myaccount component
 */
export default {

  name: "buy",

  mounted() {
    this.getUserInfo();
    this.avatarFromSQL();
    this.getAccountInfo();
    this.getAllPayments();
    this.getAccountBalance();


  },

  created() {
    //this.$cookies.set('showAccountConfirmAlert',false);
    //store.commit('Update_showAccountConfirmAlert_State',this.$cookies.get('showAccountConfirmAlert'));





    console.log('theme is : ');
    let themeVar = document.getElementById("theme-opt").toString();

    if (themeVar.includes('dark.css')) {
      this.darkTheme = true;
    }

    console.log('inside mounted navbar0');
    let token_str_flag = this.$cookies.get('access_token_skillrank');
    console.log('inside navbar: ' + token_str_flag);
    if (token_str_flag!== null) {
      console.log('token:')
      console.log(store.state.isLoggedIn)
      store.commit('UPDATE_TOKEN', this.$cookies.get("access_token_skillrank"));
      store.commit('UPDATE_USERNAME', this.$cookies.get("username_skillrank"));
      store.commit('UPDATE_USER_ID', this.$cookies.get("id_skillrank"));
      store.commit('Update_showAccountConfirmAlert_State', this.$cookies.get("showAccountConfirmAlert"));
      store.commit('UPDATE_LOGGEDIN_STATE', 1);
      /*if (this.$cookies.get("numberOfResumesViewed")!== null) {
        this.numberOfResumesViewed = this.$cookies.get("numberOfResumesViewed");
      }
      if (this.$cookies.get("expiration_date_milis")!== null) {
        this.expiration_date_milis = this.getDaysToExpiration(this.$cookies.get("expiration_date_milis"));
      }*/
    }



    this.$store.dispatch('record_event',
        {'browser_var': this.$browserDetect,'axios_var': this.axios,'root_var':this.$hostname,'cookies_var':$cookies,
          'actionType': 'open_page', 'clickedObjectId': ''});
    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e));
    window.addEventListener('unload', e => this.unloadHandler(e));


    this.getResumeViews();
    this.getTotalBalance();
    this.getNumberOfResumes();
    this.getNumberOfVacancies();

    if (this.$cookies.isKey('darkTheme')) {
      console.log('isKey check')
      if ( this.$cookies.get('darkTheme') === 'false') {
        console.log('isKey false')
        document.getElementById("theme-opt").setAttribute("href", "/css/style.css");
        document.getElementById("color-opt").setAttribute("href", "/css/colors/default.css");
      } else  {
        console.log('isKey not false')
      }
    }


    this.checkTokenValidity();
    /*
        this.in_sql.user_id = store.state.user_id;
        this.in_sql.anonymous_id = store.state.anonymous_id;
        this.in_sql.isLoggedIn = store.state.isLoggedIn;

        this.axios({
          method: 'post',
          url: this.$hostname + '/dark-theme-get?access_token=' + store.state.token,
          data: this.in_sql,
        }).then((res) => {
          if (res.data.dark_theme === 0) {
            this.darkTheme = true;
            document.getElementById("theme-opt").setAttribute("href", "./css/style-dark.css");
            document.getElementById("color-opt").setAttribute("href", "./css/colors/cyan.css");
          } else {
            this.darkTheme = false;
            document.getElementById("theme-opt").setAttribute("href", "./css/style.css");
            document.getElementById("color-opt").setAttribute("href", "./css/colors/default.css");

          };
        });
    */


  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  },
  methods: {

    avatarFromSQL(){
      this.imgUrl = this.$hostname + '/account/get-image/'+store.state.user_id+"?access_token=" + store.state.token
    },
   /* getUrl() {
        console.log('inside get url')

      return 'http://localhost:8079/account/update-avatar?access_token=e2fde9d2-96f8-41cb-bc61-ca56ba352f98'
    },*/
    onImgError() {
      this.showUrlImg = false;
    },

    getUserInfo() {
      this.user.user_id = store.state.user_id;
      this.axios({
        method: 'post',
        url: this.$hostname + '/account/get-user?access_token=' + store.state.token,
        data: this.user,
      }).then((res) => {
        this.user = res.data
      });
    },
    updateUserInfo() {
      this.axios({
        method: 'post',
        url: this.$hostname + '/account/get-user?access_token=' + store.state.token,
        data: this.user,
      }).then((res) => {
        this.$vs.notify({color:'success',title:'Успех',text:'Информация обновлена'});
      });
    },



    getResumeViews() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/events/get?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getResumeViews');
        console.log(res.data);
        this.numberOfResumesViewed = res.data.numberOfResumesViewed;
      });

    },


   /* getDaysToExpiration(exiration_date_milis) {
      var exiration_date = new Date(exiration_date_milis);
      var current_date = new Date();
      var diffInMs = Math.max(exiration_date - current_date,0);
      return diffInMs / (1000 * 60 * 60 * 24);
    }, */

    getDaysToExpiration(exiration_date_milis) {
      console.log('insode getDaysToExpiration')
      var exiration_date = new Date(exiration_date_milis);
      var current_date = new Date();
      var diffInMs = Math.max(exiration_date - current_date,0);
      console.log(diffInMs)
      return Math.round(diffInMs / (1000 * 3600 * 24),0);
    },

    handleFileUpload(){
      this.file = this.$refs.file.files[0];
      console.log('file = '  + this.file);
    },
    uploadAvatar() {
      console.log('inside uploadAvatar file = '  + this.file);
      let formData = new FormData();
      formData.append('file',this.file); /*
      this.axios.post( 'http://localhost:8079/account/update-avatar?access_token=e2fde9d2-96f8-41cb-bc61-ca56ba352f98',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(){
        console.log('SUCCESS!!');
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
          */
    },

    getTotalBalance() {

      this.in_sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: this.$hostname + '/payment/get-account-status?access_token=' + store.state.token,
        data: this.in_sql,
      }).then((res) => {
        console.log('inside getTotalBalance');
        console.log(res.data);
        this.total_balance = res.data.total_balance;
        //this.expiration_date_milis = res.data.expiration_date_milis;
        this.days_to_expiration = this.getDaysToExpiration(res.data.expiration_date_milis);
        store.commit('Update_accountTypeState', res.data.account_type);
      });


    },


    getAccountInfo() {

      this.input2sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url:
            '' +
            this.$hostname +
            '/events/get?access_token=' + store.state.token,
        data: this.input2sql,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res) => {
        //this.resumeData = res.data;
        //console.log('event resume')
        //console.log(res.data);
        this.account_statistics = res.data;
        console.log(this.account_statistics);
      });
    },

    getAllPayments() {

      this.input2sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url:
            '' +
            this.$hostname +
            '/payment/get-account-status?access_token=' + store.state.token,
        data: this.input2sql,
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then((res) => {
        if (res.data.status==='ok') {
          this.expiration_date_milis = res.data.expiration_date_milis;
          this.orders = res.data.orders;
          if (res.data.account_type === 'full') {
            this.pricingDataMonth[0].isBest = false;
            this.pricingDataMonth[1].isBest = true;
            this.pricingDataMonth.splice(0,1);
            this.pricingDataYear.splice(0,1);
          }
          console.log(this.orders);
        } else {
          this.expiration_date_milis = res.data.expiration_date_milis;
        }
      });
    },

    onChange (event) {
      this.file = event.target.files[0]
    },

    getAccountBalance() {

      this.input2sql.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url:
            '' +
            this.$hostname +
            '/payment/get-account-status?access_token=' + store.state.token,
        data: this.input2sql,
      }).then((res) => {

          this.total_balance = res.data.total_balance;

      });
    },

    addCashFromTinkov() {

      this.add_cash_sql_in.user_email = store.state.username;
      this.add_cash_sql_in.user_id = store.state.user_id;
      this.add_cash_sql_in.price = parseInt(this.add_cash_sql_in.price_rub)*100;
      console.log(this.add_cash_sql_in);
      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/payment/init?access_token=' + store.state.token,
        data: this.add_cash_sql_in,
      }).then((res) => {
        console.log(res.data)
        window.open(res.data, '_blank');
      });

    },

    successUploadImg(){
      console.log('inside successUploadImg');
      this.checkUpload.user_id = store.state.user_id;

      this.axios({
        method: 'post',
        url: '' + this.$hostname + '/account/check-if-file-upload?access_token=' + store.state.token,
        data: this.checkUpload,
      }).then((res) => {
        //this.imageNameList = res.data
        //console.log(res.data)
        this.$vs.notify({color:'success',title:'Успех',text:'Файл картинки загружен'});
      });

    },

    printError() {
      console.log('error')
      //console.log(e)
    }


  },

  computed: {
    avatarForm: function() {
      console.log('inside avatarForm');
      return {
        user_id: store.state.user_id,
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        email: this.user.email,
        company: this.user.company,
      }
    },
    deepdiveForm: function() {
      return {
        id: this.id,
      }
    }
  },


  data() {
    return {



      file:'',
      id: 1,
      checkUpload: {
        user_id: 1,
        file_type: 'pdf',
      },
      imgUrl:'',
      user: {
        user_id: 1,
        first_name: '',
        last_name: '',
        email: '',
        company: '',
      },
      showUrlImg: true,

      input2sql: {
          user_id: 1,
      },

      orders: [],
      expiration_date_milis: 0,

      total_balance: 0,

      add_cash_sql_in: {
        user_email: '',
        user_id: 0,
        service_name: 'Депозит Skillrank',
        description: 'Депозит Skillrank',
        price: 0,
        price_rub: 0,
        quantity: 1,
      },

      in_sql: {
        user_id: 0,
        anonymous_id:0,
        dark_theme:0,
        isLoggedIn:0,
      },



     /* members: [
        {first_name:'igor',last_name:'gon'},
        {first_name:'anton',last_name:'ve'}
      ],*/

      numberOfResumesViewed:0,
      days_to_expiration:0,
      numberOfResumesInCart:0,



      account_statistics: {
          numberOfResumesInSearch: 0,
          numberOfResumesViewed: 0,
          numberOfResumesSaved: 0,
          numberOfEmailsSent: 0,
      },

   /*   pricingDataMonth: [
        {
          title: "Пробный доступ",
          price: 0,
          quantity: 1,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный 3-х дневный Доcтуп",
          price: 250,
          quantity: 1,
          period: "3 дня",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Полный 7-ми дневный доступ",
          price: 400,
          quantity: 1,
          period: "7 дней",
          feature: [
            "Полный доступ и интеграция с ATS",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ], */

      pricingDataMonth: [



        {
          title: "Подписка на платформу",
          price: 1990,
          quantity: 1,
          period: "месяц",
          feature: [
            "Первые 3 дня бесплатно, затем 1990р в месяц",
            // "Неограниченные рассылки",
            // "Аналитика по рассылкам",
            //"Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
      ],

      pricingDataYear: [
        {
          title: "Пробный доступ",
          price: 0,
          quantity: 12,
          period: "мес",
          feature: [
            "10 просмотров резюме",
            "5 писем рассылки",
            "Аналитика по рассылке",
          ],
          button: "Получить",
          isBest: true,
        },
        {
          title: "Полный Доcтуп",
          price: 5990,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ к базе резюме",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
          ],
          button: "Купить",
          isBest: false,
        },
        {
          title: "Интеграция",
          price: 7490,
          quantity: 12,
          period: "мес",
          feature: [
            "Полный доступ и интеграция с ATS",
            "Неограниченные рассылки",
            "Аналитика по рассылкам",
            "Консультации рекрутера",
            "Интеграция с ATS или CRM"
          ],
          button: "Купить",
          isBest: false,
        },
      ],


    };
  },
  components: {
    PricingNew,
    Navbar,
    Pricing,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    HeartIcon,
    EyeIcon,
    ShoppingCartIcon,
    KeyIcon,
    PhoneIcon,
    MailIcon,
    SettingsIcon,
    MapPinIcon,
    UserIcon,UsersIcon,
    UserCheckIcon,
    UserPlusIcon,
    FileTextIcon,
    MemberHighlight,
    LayersIcon,
  },
};
</script>

<template>
  <div>
    <Navbar />

    <!-- Hero Start
    <section class="bg-half bg-light d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title">My Profile / Account</h4>
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item">
                      <router-link to="/">Landrick</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/index-shop">Shop</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      My Account
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>

        </div>

      </div>

    </section>
    -->
    <!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg
          viewBox="0 0 2880 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
    <!-- Hero End -->

    <!-- Start -->
    <section class="section">
      <div class="container">
        <div class="rounded p-4">
          <div class="d-flex media align-items-center justify-content-between">
            <div class="media align-items-center">
              <img
                  :src="imgUrl"
                  class="avatar avatar-md-md rounded-circle"
                  alt="content-img"
                  @error="onImgError()"
                  v-if="showUrlImg"
            />
              <i v-else class="uil uil-user avatar rounded-circle"> </i>
            <div class="ml-3">
              <h6 class="text-muted mb-0" v-if="user.first_name.length>0">Здравствуйте, </h6>
              <h6 class="text-muted mb-0" v-else>Здравствуйте </h6>
              <h5 class="mb-0" v-if="user.first_name.length>0">{{user.first_name}}</h5>
            </div>
            </div>
            <h5 class="mb-0">Статистика резюме:</h5>
            <div class="">
              <file-text-icon class="fea icon-ex-md text-primary mb-1"></file-text-icon>
              <h5 class="mb-0">{{ account_statistics.numberOfResumesInSearch }}</h5>
              <p class="text-muted mb-0">В поиске</p>
            </div>

            <div class="">
              <users-icon class="fea icon-ex-md text-primary mb-1"></users-icon>
              <h5 class="mb-0">{{ account_statistics.numberOfResumesViewed }}</h5>
              <p class="text-muted mb-0">Просмотрено</p>
            </div>
            <div class="">
              <user-plus-icon class="fea icon-ex-md text-primary mb-1"></user-plus-icon>
              <h5 class="mb-0">{{ account_statistics.numberOfResumesSaved }}</h5>
              <p class="text-muted mb-0">Сохранено</p>
            </div>
            <div class="">
              <mail-icon class="fea icon-ex-md text-primary mb-1"></mail-icon>
              <h5 class="mb-0">{{ account_statistics.numberOfEmailsSent }}</h5>
              <p class="text-muted mb-0">Писем</p>
            </div>

          </div>

          <div class="mt-5 justify-content-between">

          </div>

          <div class="mt-5">

            <b-tabs
              pills
              justified
              vertical
              nav-wrapper-class="col-md-4 shadow"
              nav-class="bg-white rounded mt-4 p-3 mb-0"
              content-class="col-md-8 col-12 mt-8 pt-2 border"
            >
              <b-tab active>
                <template #title>
                  <div class="text-left py-1 px-3">
                    <h6 class="mb-0">
                      <i
                        class="uil uil-dashboard h5 align-middle mr-2 mb-0"
                      ></i>
                      Подписка
                    </h6>
                  </div>
                </template>

                <div v-if="$store.state.account_type=='demo'">
                  <PricingNew :pricingDataMonth="pricingDataMonth" />
                </div>
                <div v-else>
                  <!-- Additional Content Start -->
                  <div class="col mt-4 pt-2">
                    <div class="component-wrapper rounded shadow">
                      <div class="p-4 border-bottom">
                        <h4 class="title mb-0">Подписка активна</h4>
                      </div>

                      <div class="p-4">
                        <div class="alert alert-success mb-0" role="alert">
                          <!--<h4 class="alert-heading">Well done!</h4> -->
                          <div class="align-text-center border-bottom">
                          <h2>
                            На счете: {{  new Intl.NumberFormat().format(total_balance).replace(',', ' ')  }} руб.
                          </h2>
                            </div>
                          <div class="mt-3">



                           <h2 v-if="days_to_expiration>=5">
                              Доступ: {{days_to_expiration}} дней
                            </h2>
                            <h2 v-if="days_to_expiration<5 && days_to_expiration>1">
                              Доступ: {{days_to_expiration}} дня
                            </h2>
                            <h2 v-if="days_to_expiration===1">
                              Доступ: {{days_to_expiration}} день
                            </h2>
                            </div>

                        </div>
                        <div class="col">
                          <div class="row-12">
                        <div class="align-items-center mt-2">
                          <a size="xl" type="button" class="btn btn-primary btn-block vs-con-loading__container">
                            Отменить подписку
                          </a>
                          </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <!-- Additional Content End -->

                <!--  <div class="row justify-content-center mb-5">
                    <div class="col-lg-12 text-center">
                      <div class="page-next-level">
                        <!--<img src="images/job/Gradle.svg" class="avatar avatar-small" alt="">--
                        <!--<h4 class="title mt-4 mb-3"> {{job.job_title}} </h4>--

                        <h1 class="title mt-1 mb-1"> Подписка активна </h1>

                        <!-- <h1 class="title mt-4 mb-3">   </h1>--
                        <p class="para-desc mx-auto text-muted"></p>







                        <!-- <PricingMonth :pricingDataMonth="pricingDataMonthWeek" /> --

                        <h2>
                          На счете <br>{{  new Intl.NumberFormat().format(total_balance).replace(',', ' ')  }} руб.
                        </h2>








                        <h2 v-if="days_to_expiration>=5">
                          Доступ: {{days_to_expiration}} дней
                        </h2>
                        <h2 v-if="days_to_expiration<5 && days_to_expiration>1">
                          Доступ: {{days_to_expiration}} дня
                        </h2>
                        <h2 v-if="days_to_expiration===1">
                          Доступ: {{days_to_expiration}} день
                        </h2>
                        <h3>Отменить подписку</h3>


                      </div>
                    </div>
                  </div> -->
                </div>


              </b-tab>

              <b-tab title-link-class="border-top">
                <template #title>
                  <div class="text-left py-1 px-3">
                    <h6 class="mb-0">
                      <i class="uil uil-list-ul h5 align-middle mr-2 mb-0"></i>
                      Мои заказы
                    </h6>
                  </div>
                </template>

                <div class="table-responsive bg-white shadow rounded">
                  <table class="table mb-0 table-center table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">№</th>
                        <th scope="col">Описание</th>
                        <th scope="col">Дата завершения</th>
                        <th scope="col">Статус</th>
                        <th scope="col">Сумма</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="order in orders">
                        <th scope="row">{{order.order_id}}</th>
                        <td>{{ order.description }}</td>
                        <td>{{ order.contact_expiration }}</td>
                        <td :class="order.color">{{ order.status }}</td>
                        <td>
                          ₽ {{order.amount}}
                        </td>

                      </tr>
                    </tbody>
                  </table>
                </div>


              </b-tab>



              <b-tab title-link-class="border-top">
                <template #title>
                  <div class="text-left py-1 px-3">
                    <h6 class="mb-0">
                      <i
                        class="uil uil-arrow-circle-down h5 align-middle mr-2 mb-0"
                      ></i>
                      Докупка контактов
                    </h6>
                  </div>
                </template>

                <div>
                  <p class="text-center"> В корзине:  резюме</p>
                  <br>
                  <a
                      href="/cart"
                      class="btn btn-outline-primary rounded text-center"
                  ><i class="mdi mdi-google-my-business"></i> Перейти в корзину</a
                  >
                </div>
                <!--
                <div class="table-responsive bg-white shadow rounded">
                  <table class="table mb-0 table-center table-nowrap">
                    <thead>
                      <tr>
                        <th scope="col">Имя</th>
                        <th scope="col">Должность</th>
                        <th scope="col">Статус</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Игорь Гончаренко</th>
                        <td class="text-muted">
                          It is said that song composers of the past <br />
                          used dummy texts as lyrics when writing <br />
                          melodies in order to have a 'ready-made' <br />
                          text to sing with the melody.
                        </td>
                        <td class="text-success">Downloaded</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                -->

              </b-tab>

              <b-tab title-link-class="border-top">
                <template #title>
                  <div class="text-left py-1 px-3">
                    <h6 class="mb-0">
                      <i
                          class="uil uil-money-bill h5 align-middle mr-2 mb-0"
                      ></i>
                      Мой счет
                    </h6>
                  </div>
                </template>

                <div>
                  <p class="text-center"> На счете: {{total_balance}} рублей</p>
                  <br>
                  <div class="form-group">
                    <label>Сумма депозита (в рублях)</label>
                    <div class="position-relative">
                      <layers-icon
                          class="fea icon-sm icons"
                      ></layers-icon>
                      <input
                          name="name"
                          type="text"
                          class="form-control pl-5"
                          v-model="add_cash_sql_in.price_rub"
                      />
                    </div>
                  </div>

                  <a
                      @click="addCashFromTinkov"
                      class="btn btn-outline-primary rounded text-center"
                  ><i class="uil uil-plus"></i> Добавить</a
                  >
                </div>

              </b-tab>

            <!--  <b-tab>
                <template #title>
                  <div class="text-left py-1 px-3">
                    <h6 class="mb-0">
                      <i class="uil uil-users-alt h5 align-middle mr-2 mb-0"></i>
                      Команда
                    </h6>
                  </div>
                </template>

                <div class="row">
                  <h5>Команда:</h5> <a href="javascript:void(0)" class="btn btn-outline-info ml-5"  >
                    <user-plus-icon class="fea icon-sm fea-social"></user-plus-icon>
                  </a>
                </div>

                <div class="row">

                  <member-highlight :member="member" :index="ix" v-for="(member,ix) in members"  />

                </div>

              </b-tab> -->
              <b-tab>
                <template #title>
                  <div class="text-left py-1 px-3">
                    <h6 class="mb-0">
                      <i class="uil uil-user h5 align-middle mr-2 mb-0"></i>
                      Личные данные
                    </h6>
                  </div>
                </template>

                <form>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Имя</label>
                        <div class="position-relative">
                          <user-icon class="fea icon-sm icons"></user-icon>
                          <input
                            name="name"
                            id="first-name"
                            type="text"
                            class="form-control pl-5"
                            v-model="user.first_name"
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Фамилия</label>
                        <div class="position-relative">
                          <user-check-icon
                            class="fea icon-sm icons"
                          ></user-check-icon>
                          <input
                            name="name"
                            id="last-name"
                            type="text"
                            class="form-control pl-5"
                            v-model="user.last_name"
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Email</label>
                        <div class="position-relative">
                          <mail-icon class="fea icon-sm icons"></mail-icon>
                          <input
                            name="email"
                            id="email"
                            type="email"
                            class="form-control pl-5"
                            v-model="user.email"
                          />
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Компания</label>
                        <div class="position-relative">
                          <user-check-icon
                            class="fea icon-sm icons"
                          ></user-check-icon>
                          <input
                            name="name"
                            id="display-name"
                            type="text"
                            class="form-control pl-5"
                            v-model="user.company"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                    <!--end col-->
                    <!--
                    <div  >

                      <vs-upload class=" text-info ml-5 pl-2"
                                 action="http://localhost:8079/account/update-avatar?access_token=e2fde9d2-96f8-41cb-bc61-ca56ba352f98"
                                 fileName='avatar' text='Загрузить фото'
                                  />

                    </div>
                    -->
                </form>
                    <!--<input type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>



                    <div class="col-lg-12 mt-2 mb-0">
                      <button class="btn btn-primary" @click="uploadAvatar"> Сохранить</button>
                    </div>
                    -->
                <div class="row">
                  <div class="col-5">

                    <div class="form-group position-relative">
                      <label>Загрузить фото :</label>
                      <input
                          type="file"
                          class="form-control-file"
                          ref="fotoFile"
                          @change="onChange"
                      />
                    </div>
                    <!-- <vs-upload class="text-info"
                           :action="this.$hostname +'/account/update-avatar?access_token='+this.$cookies.get('access_token_skillrank')"
                           fileName='avatar' :data="avatarForm" text='Загрузить фото'
                />  -->
                  </div>
                </div>
                <div class="row">
                <div class="col-lg-12 mt-2 mb-0">
                  <button class="btn btn-primary" @click="updateUserInfo"> Сохранить</button>
                </div>
                </div>
                <!--
                <h5 class="mt-4">Изменение пароля :</h5>
                <form>
                  <div class="row mt-3">
                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Текущий пароль :</label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Текущий пароль"
                            required=""
                          />
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Новый пароль :</label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Новый пароль"
                            required=""
                          />
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-12">
                      <div class="form-group">
                        <label>Повторите новый пароль :</label>
                        <div class="position-relative">
                          <key-icon class="fea icon-sm icons"></key-icon>
                          <input
                            type="password"
                            class="form-control pl-5"
                            placeholder="Повторите новый пароль"
                            required=""
                          />
                        </div>
                      </div>
                    </div>


                    <div class="col-lg-12 mt-2 mb-4">
                      <button class="btn btn-primary">Изменить пароль</button>
                    </div>

                  </div>

                </form>
                -->
              </b-tab>




            </b-tabs>
          </div>
          <!--end row-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

    </section>
    <!--end section-->
    <!-- End -->
    <!--end section-->
    <Footer />
    <!-- Footer End
    <Switcher />
    -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
