<template>

  <div class="col-lg-6 col-md-4 mt-4 pt-2">
    <div class="text-center">
      <img src="images/client/01.jpg" class="avatar avatar-medium shadow-lg rounded-pill" alt="">
      <div class="content mt-3">
        <a href="javascript:void(0)" class="h5 text-dark">{{member.first_name}} {{member.last_name}}</a>
        <br>
        <a href="javascript:void(0)" class="btn btn-primary mt-2">
          <mail-icon class="fea icon-sm "></mail-icon> Написать
        </a>
      </div>
    </div>
  </div>

</template>

<script>
import {
MailIcon,
} from "vue-feather-icons";

export default {
  name: "MemberHighlight",
  props: {
    member: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },

  },
  components: {
    MailIcon,
  }
}
</script>

<style scoped>

</style>